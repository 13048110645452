import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

import get from "lodash/get";

import { useTranslation } from "react-i18next";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import { QUERY_ACCESS_EVENT } from "../../../../config/graphql/query";

export const getOwnerField = (
  owner:
    | IMember
    | IMeetingAttendee
    | IMmmrDriver
    | IMmmrVisitor
    | IMmmrAdditionalVisitor
    | IGuest
    | undefined,
  fieldName: string,
): string => {
  if (!owner) {
    return "";
  }

  if (fieldName in owner) {
    return get(owner, [fieldName]);
  }

  if ("fields" in owner) {
    const field = owner.fields.find((field) => field.name === fieldName);

    return field ? field.value : "";
  }

  return "";
};

const AccessEvent = React.memo(() => {
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation(["accessEvent", "common"]);

  const { data: accessEventData } = useQuery<{
    accessEvent?: IAccessEvent;
  }>(QUERY_ACCESS_EVENT, {
    skip: !id,
    variables: { id },
  });

  let accessType = get(accessEventData, "accessEvent.type") as string;

  const owner = accessEventData?.accessEvent?.owner;

  const ownerEmail = getOwnerField(owner, "email");
  const ownerFullName = getOwnerField(owner, "fullName");
  const ownerCompany = getOwnerField(owner, "company");
  const ownerPhone = getOwnerField(owner, "phone");

  let ownerBadge = get(owner, "badge");
  const ownerFunction = get(owner, "function");

  const isMaintenance = get(owner, "isMaintenance");

  if (isMaintenance) {
    accessType = "MmmrMaintenance";
    ownerBadge = get(owner, "badgeNumber");
  }

  const receiverEmail =
    get(accessEventData, "accessEvent.receiver.email") ||
    get(accessEventData, "accessEvent.customReceiver.email");
  const receiverFullName =
    get(accessEventData, "accessEvent.receiver.fullName") ||
    get(accessEventData, "accessEvent.customReceiver.fullName");

  const checkOut = get(accessEventData, "accessEvent.metadata.checkOut");
  const checkTime = get(accessEventData, "accessEvent.createdAt");

  let additionalInfo = !!ownerBadge || !!ownerFunction || !!ownerCompany;

  let additionalGuestFields: IGuestField[] = [];

  if (owner && "fields" in owner) {
    additionalGuestFields = owner.fields.filter(
      (field) =>
        !["email", "fullName", "phone", "company"].includes(field.name),
    );

    additionalInfo = additionalInfo || additionalGuestFields.length !== 0;
  }

  return (
    <div className="container-fluid">
      <h6 className="mb-3">{t("accessEvent:accessEvent.heading.primary")}</h6>
      <Card className="mb-3 p-3">
        {t("accessEvent:accessEvent.card.logType")}
        {t(`accessEvent:type.${accessType}`)}
      </Card>
      {accessType !== "Package" && accessType !== "Food" && (
        <>
          <Card className="mb-3 p-3">
            {t("accessEvent:accessEvent.card.fullName")}
            {ownerFullName}
          </Card>

          {ownerEmail && (
            <Card className="mb-3 p-3">
              {t("accessEvent:accessEvent.card.email")}
              {ownerEmail}
            </Card>
          )}
          {ownerPhone && (
            <Card className="mb-3 p-3">
              {t("accessEvent:accessEvent.card.ownerPhone")}
              {ownerPhone}
            </Card>
          )}
          <Card className="mb-3 p-3">
            {t("accessEvent:accessEvent.card.checkStatus")}
            {checkOut
              ? t("accessEvent:accessEvent.card.checkOut")
              : t("accessEvent:accessEvent.card.checkIn")}
          </Card>
        </>
      )}
      <Card className="mb-3 p-3">
        {t("accessEvent:accessEvent.card.checkTime")}
        {dayjs(checkTime).local().format("DD-MM-YYYY / HH:mm")}
      </Card>

      {(accessType === "Package" ||
        accessType === "Food" ||
        ((accessType === "Guest" ||
          accessType === "Visitor" ||
          accessType === "MmmrVisitor" ||
          accessType === "MmmrMaintenance" ||
          accessType === "MmmrAdditionalVisitor") &&
          accessEventData?.accessEvent?.receiver)) && (
        <Accordion defaultActiveKey="0" className="mb-3">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0" className="p-3">
              {accessType === "Package" &&
                t("accessEvent:accessEvent.card.packageFor")}
              {accessType === "Food" &&
                t("accessEvent:accessEvent.card.foodFor")}
              {(accessType === "Guest" ||
                accessType === "Visitor" ||
                accessType === "MmmrVisitor" ||
                accessType === "MmmrMaintenance" ||
                accessType === "MmmrAdditionalVisitor") &&
                t("accessEvent:accessEvent.card.visitFor")}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="p-3">
                {t("accessEvent:accessEvent.card.fullName")}
                {receiverFullName}
              </Card.Body>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="p-3">
                {t("accessEvent:accessEvent.card.email")}
                {receiverEmail}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}
      {accessType === "MeetingAttendee" && (
        <Accordion defaultActiveKey="0" className="mb-3">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0" className="p-3">
              {t("accessEvent:accessEvent.card.meeting")}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="p-3">
                {t("accessEvent:accessEvent.card.meetingOrganizerFullName")}
                {accessEventData?.accessEvent?.meeting?.organizer?.fullName}
              </Card.Body>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="p-3">
                {t("accessEvent:accessEvent.card.meetingOrganizerEmail")}
                {accessEventData?.accessEvent?.meeting?.organizer?.email}
              </Card.Body>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="p-3">
                {t("accessEvent:accessEvent.card.meetingTime")}
                {dayjs(accessEventData?.accessEvent?.meeting?.dtstart)
                  .local()
                  .format("DD-MM-YYYY / HH:mm")}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}
      {additionalInfo && (
        <Accordion defaultActiveKey="0" className="mb-3">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0" className="p-3">
              {t("accessEvent:accessEvent.card.additionalInfo")}
            </Accordion.Toggle>
            {!!ownerBadge && (
              <Accordion.Collapse eventKey="0">
                <Card.Body className="p-3">
                  {t("accessEvent:accessEvent.card.badge")}
                  {ownerBadge}
                </Card.Body>
              </Accordion.Collapse>
            )}
            {!!ownerFunction && (
              <Accordion.Collapse eventKey="0">
                <Card.Body className="p-3">
                  {t("accessEvent:accessEvent.card.function")}
                  {ownerFunction}
                </Card.Body>
              </Accordion.Collapse>
            )}
            {!!ownerCompany && (
              <Accordion.Collapse eventKey="0">
                <Card.Body className="p-3">
                  {t("accessEvent:accessEvent.card.company")}
                  {ownerCompany}
                </Card.Body>
              </Accordion.Collapse>
            )}
            {additionalGuestFields.map((field) => (
              <Accordion.Collapse eventKey="0" key={field.name}>
                <Card.Body className="p-3">
                  {field.name} : {field.value}
                </Card.Body>
              </Accordion.Collapse>
            ))}
          </Card>
        </Accordion>
      )}
    </div>
  );
});

export default AccessEvent;
