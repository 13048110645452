import React, { useCallback, useMemo, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useDebounce } from "react-use";

import dayjs from "dayjs";
import qs from "query-string";

import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";

import { QUERY_CHECKED_IN } from "../../../config/graphql/query";
import { getOwnerField } from "../AccessEvents/AccessEvent";

const limit = 10;

const TableRow = ({ item }: { item: IAccessEvent }) => {
  const { type, owner, createdAt } = item;

  const { t } = useTranslation(["accessEvent"]);

  const fullName = getOwnerField(owner, "fullName");
  const email = getOwnerField(owner, "email");
  const phone = getOwnerField(owner, "phone");

  return (
    <>
      <tr>
        <td>{fullName}</td>
        <td>{email}</td>
        <td>{phone}</td>
        <td>{t(`accessEvent:type.${type}`)}</td>
        <td>{dayjs(createdAt).local().format("DD-MM-YYYY / HH:mm")}</td>
      </tr>
    </>
  );
};

const CheckedIn = React.memo(() => {
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation(["checedIn", "common"]);

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
      },
    [location.search],
  );

  const [search, setSearch] = useState(query.search);

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const variables = useMemo(() => {
    return {
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      ...(query.search
        ? {
            filter: {
              owner: {
                fullName: query.search,
                email: query.search,
              },
            },
          }
        : {}),
    };
  }, [page, query.search]);

  const { data } = useQuery<{
    currentlyCheckedIn?: {
      events: Array<IAccessEvent>;
      eventsCount: number;
    };
  }>(QUERY_CHECKED_IN, {
    fetchPolicy: "network-only",
    variables,
  });

  const accessEvents: Array<IAccessEvent> =
    data?.currentlyCheckedIn?.events ?? [];
  const accessEventsCount: number = data?.currentlyCheckedIn?.eventsCount ?? 0;

  const renderItem = useCallback(
    (item: IAccessEvent) => <TableRow key={item.id} item={item} />,
    [],
  );

  useDebounce(
    () => {
      if (search !== undefined) {
        history.push({
          search: qs.stringify({
            page: 1,
            ...(search && { search }),
          }),
        });
      }
    },
    500,
    [search],
  );

  return (
    <div className="container-fluid">
      <div className="mt-3 mb-4 justify-content-between">
        <p className="my-3">{t("checkedIn:info")}</p>
        <form className="form-inline justify-content-between">
          <div className="form-group">
            <label htmlFor="search" className="sr-only">
              {t("common:search")}
            </label>
            <input
              id="search"
              type="search"
              className="form-control"
              placeholder={t("common:search")}
              value={search}
              onChange={({ target: { value } }) => {
                setSearch(value);
              }}
            />
          </div>
        </form>
      </div>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th scope="col">{t("checkedIn:th.fullName")}</th>
            <th scope="col">{t("checkedIn:th.email")}</th>
            <th scope="col">{t("checkedIn:th.phone")}</th>
            <th scope="col">{t("checkedIn:th.type")}</th>
            <th scope="col">{t("checkedIn:th.time")}</th>
          </tr>
        </thead>
        <tbody>{accessEvents.map(renderItem)}</tbody>
      </Table>
      <Pagination documentsCount={accessEventsCount} limit={limit} />
    </div>
  );
});

export default CheckedIn;
