import { useCallback } from "react";

import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "libphonenumber-js/min";

import { QUERY_DIGITAL_RECEPTION_SETTINGS } from "../../../../../config/graphql/query";
import { UPDATE_DIGITAL_RECEPTION_SETTINGS } from "../../../../../config/graphql/mutation";

import Input from "../../../../../components/Input";

interface FormValues extends IDigitalReceptionSettings {}

const GeneralSettings = () => {
  const methods = useForm<FormValues>({
    shouldUnregister: false,
  });

  const { screenId } = useParams<{ screenId: string }>();

  const { t } = useTranslation(["screens", "common"]);

  useQuery<{
    digitalReception: IDigitalReception;
  }>(QUERY_DIGITAL_RECEPTION_SETTINGS, {
    skip: !screenId,
    variables: {
      id: screenId,
    },
    onCompleted: ({ digitalReception: { settings } }) => {
      if (!settings) {
        return;
      }

      methods.reset(settings);
    },
  });

  const [onUpdate] = useMutation(UPDATE_DIGITAL_RECEPTION_SETTINGS, {
    refetchQueries: [
      {
        query: QUERY_DIGITAL_RECEPTION_SETTINGS,
        variables: { id: screenId },
      },
    ],
  });

  const onSubmit = useCallback(
    async (settings: FormValues) => {
      const { support, receptionistPhone, dispenser } = settings;

      onUpdate({
        variables: {
          input: {
            id: screenId,
            settings: {
              support: {
                email: support?.email || null,
              },
              receptionistPhone: {
                primary: receptionistPhone?.primary || null,
                backup: receptionistPhone?.backup || null,
              },
              dispenser: {
                id: dispenser.id || null,
                socketUrl: dispenser.socketUrl || null,
              },
            },
          },
        },
      }).then(() => {
        toast.success<string>(t("screens:screen.screenRoute.toast.updated"));
      });
    },
    [onUpdate, t, screenId],
  );

  return (
    <div className="container-fluid">
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group mb-4">
              <h6>{t("screens:screen.settings.support.title")}</h6>
              <div className="form-group">
                <label htmlFor="supportEmail">
                  {t("screens:screen.settings.support.email")}
                </label>
                <Input
                  name="support.email"
                  autoComplete="email"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group mt-4 mb-4">
              <h6>{t("screens:screen.settings.receptionistPhone.title")}</h6>
              <div className="form-group">
                <label htmlFor="phone">
                  {t("screens:screen.settings.receptionistPhone.primaryPhone")}
                </label>
                <Input
                  name="receptionistPhone.primary"
                  className="form-control"
                  rules={{
                    validate: {
                      invalid: (value) => {
                        if (!value) {
                          return true;
                        }
                        return (
                          isValidPhoneNumber(value) ||
                          t("employee:input.error.phone.format")
                        );
                      },
                    },
                  }}
                />
                <small className="form-text text-muted small">
                  {t("employee:input.label.phone-help")}
                </small>
              </div>
            </div>
            <div className="form-group mt-4 mb-4">
              <h6>{t("screens:screen.settings.dispenser.title")}</h6>
              <div className="form-group">
                <label htmlFor="dispenserId">
                  {t("screens:screen.settings.dispenser.id")}
                </label>
                <Input name="dispenser.id" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="dispenserSocketUrl">
                  {t("screens:screen.settings.dispenser.socketUrl")}
                </label>
                <Input name="dispenser.socketUrl" className="form-control" />
              </div>
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default GeneralSettings;
