/* eslint-disable no-restricted-syntax */
import { useCallback, useRef, useEffect } from "react";

import {
  useForm,
  useWatch,
  Controller,
  FieldValues,
  FormProvider,
  ControllerFieldState,
  ControllerRenderProps,
  useFieldArray,
  UseFieldArrayRemove,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import get from "lodash/get";
import set from "lodash/set";
import omit from "lodash/omit";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { QUERY_DIGITAL_RECEPTION_SETTINGS } from "../../../../../config/graphql/query";
import { UPDATE_DIGITAL_RECEPTION_SETTINGS } from "../../../../../config/graphql/mutation";

import { useCurrentLanguage } from "../../../../../lib/hooks/useCurrentLanguage";

import { useCurrentHasPlan } from "../../../../../components/PlanBlock";

const FormCheckbox = (props: {
  form: {
    field:
      | ControllerRenderProps<FieldValues, `${string}.enabled`>
      | ControllerRenderProps<FieldValues, `${string}.required`>;
    fieldState: ControllerFieldState;
  };
  label: React.ReactNode;
  onChangePreview?: (name: string, value: any) => any;
  isDisabled?: boolean;
}) => {
  const {
    form: {
      field: { value, name, onChange },
      fieldState: { error },
    },
    label,
    onChangePreview,
    isDisabled,
  } = props;

  return (
    <div className="form-group form-check mb-1">
      <input
        id={name}
        type="checkbox"
        className="form-check-input"
        checked={value}
        onChange={(value) => {
          onChange(value);
          if (onChangePreview) {
            onChangePreview(name, value.target.checked);
          }
        }}
        disabled={isDisabled}
      />
      <label className="form-check-label user-select-none" htmlFor={name}>
        {label}
      </label>
      {!!error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  );
};

const HomeButtonText = (props: {
  name: string;
  onChangePreview: (name: string, value: any) => any;
}) => {
  const { name, onChangePreview } = props;

  const { t } = useTranslation(["screens"]);

  const language = useCurrentLanguage() as "en" | "nl";

  const enabled = useWatch({ name: `${name}.enabled` });

  if (!enabled) {
    return null;
  }

  return (
    <div className="form-group mb-4">
      <label htmlFor={`${name}.text`}>
        {t("screens:screen.settings.layout.text")}
      </label>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">{language.toUpperCase()}</span>
        </div>
        <Controller
          name={`${name}.text`}
          render={({ field: { onChange, ...props } }) => (
            <input
              onChange={(value) => {
                onChange(value);
                onChangePreview(`${name}.text.${language}`, value.target.value);
              }}
              {...props}
              className="form-control"
            />
          )}
        />
      </div>
    </div>
  );
};

const HomeButtonController = (props: {
  name: string;
  label: string;
  onChangePreview: (name: string, value: any) => void | undefined;
  isDisabled?: boolean;
}) => {
  const { name, label, onChangePreview, isDisabled } = props;

  return (
    <div style={{ marginBottom: "2.25rem" }}>
      <Controller
        name={`${name}.enabled`}
        render={(form) => (
          <FormCheckbox
            form={form}
            label={label}
            onChangePreview={onChangePreview}
            isDisabled={isDisabled}
          />
        )}
      />
      <HomeButtonText name={name} onChangePreview={onChangePreview} />
    </div>
  );
};

const ManualCheckinFieldText = (props: { name: string }) => {
  const { name } = props;

  const language = useCurrentLanguage() as "en" | "nl";

  return (
    <div className="form-group mb-4 mt-2 col-8 px-0">
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">{language.toUpperCase()}</span>
        </div>
        <Controller
          name={`${name}.fieldLabel`}
          render={({ field: { onChange, ...props } }) => (
            <input
              onChange={(value) => {
                onChange(value);
              }}
              {...props}
              className="form-control"
            />
          )}
        />
      </div>
    </div>
  );
};

const ManualCheckinFieldController = (props: {
  name: string;
  label: string;
  index: number;
  remove: UseFieldArrayRemove;
}) => {
  const { name, label, index, remove } = props;

  const { t } = useTranslation(["screens"]);

  return (
    <div className="mb-3">
      <div className="d-flex align-items-center mb-1">
        <Controller
          name={`${name}.required`}
          render={(form) => <FormCheckbox form={form} label={label} />}
        />
        <div>
          <Controller
            name={`${name}.fieldName`}
            render={({ field: { onChange, ...props } }) => (
              <input
                onChange={(value) => {
                  onChange(value);
                }}
                {...props}
                className="form-control ml-3"
              />
            )}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-start">
        <ManualCheckinFieldText name={name} />
        <button
          type="button"
          className="btn btn-danger col-3 mt-2 px-1"
          onClick={() => {
            remove(index);
          }}
        >
          {t("screens:screen.settings.layout.removeField")}
        </button>
      </div>
    </div>
  );
};

interface FormValues {
  layout: IDigitalReceptionLayout<string>;
}

const Layout = () => {
  const methods = useForm<FormValues>({
    shouldUnregister: false,
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "layout.manualCheckinFields.fields",
  });

  const { screenId } = useParams<{ screenId: string }>();

  const language = useCurrentLanguage() as "en" | "nl";

  const isFree = useCurrentHasPlan(["free"]);

  const previewRef = useRef<HTMLIFrameElement>(null);

  const { t } = useTranslation(["screens", "common"]);

  const { data: screenData } = useQuery<{
    digitalReception: IDigitalReception;
  }>(QUERY_DIGITAL_RECEPTION_SETTINGS, {
    skip: !screenId,
    variables: {
      id: screenId,
    },
    onCompleted: ({ digitalReception: { settings } }) => {
      if (!settings) {
        return;
      }

      const { layout, ...rest } = settings;

      const layoutForReset: IDigitalReceptionLayout<string> = {};

      if (layout) {
        for (const [key, value] of Object.entries(layout)) {
          if (key !== "__typename") {
            if (key === "welcomeMessage") {
              layoutForReset[key] = get(value, language);
            } else if (key === "manualCheckinFields") {
              layoutForReset[key] = {
                type: get(value, "type"),
                fields: get(value, "fields").map((field: any) => ({
                  required: get(field, "required"),
                  fieldLabel: get(field, `fieldLabel.${language}`),
                  fieldName: get(field, `fieldName`),
                })),
              };
            } else {
              layoutForReset[
                key as keyof Omit<
                  IDigitalReceptionLayout<string>,
                  "welcomeMessage" | "manualCheckinFields"
                >
              ] = {
                enabled: get(value, "enabled"),
                text: get(value, `text.${language}`),
              };
            }
          }
        }
      }

      methods.reset({ layout: layoutForReset, ...rest });
    },
  });

  const isIntegratedManualCheckinEnabled = methods.watch(
    "layout.integratedManualCheckin.enabled",
  );

  const isIndependentManualCheckinEnabled = methods.watch(
    "layout.independentManualCheckin.enabled",
  );

  const postMessage = useCallback(
    (message: string, targetOrigin = "*") => {
      return previewRef?.current?.contentWindow?.postMessage(
        message,
        targetOrigin,
      );
    },
    [previewRef],
  );

  const onChangePreview = useCallback(
    (name: string, value: any) => {
      const data = {};

      set(data, ["digitalReception", "settings", ...name.split(".")], value);

      const message = JSON.stringify({
        action: "update-digitalReception",
        data,
      });

      return postMessage(message);
    },
    [postMessage],
  );

  useEffect(() => {
    const layout = get(screenData, "digitalReception.settings.layout");

    if (!layout) {
      return;
    }

    for (const [key, value] of Object.entries(
      layout as IDigitalReceptionLayout<{ en?: string; nl?: string }>,
    )) {
      if (key !== "__typename") {
        if (key === "welcomeMessage") {
          methods.setValue(`layout.${key}`, get(value, language));
        } else if (key === "manualCheckinFields") {
          value.fields.forEach((field: any, i: number) => {
            methods.setValue(
              `layout.${key}.fields.${i}.fieldLabel`,
              get(field, `fieldLabel.${language}`) || "",
            );
          });
        } else {
          methods.setValue(
            `layout.${
              key as keyof Omit<
                IDigitalReceptionLayout<string>,
                "welcomeMessage" | "manualCheckinFields"
              >
            }.text`,
            get(value, `text.${language}`),
          );
        }
      }
    }
  }, [language]);

  const [onUpdate] = useMutation(UPDATE_DIGITAL_RECEPTION_SETTINGS, {
    refetchQueries: [
      {
        query: QUERY_DIGITAL_RECEPTION_SETTINGS,
        variables: { id: screenId },
      },
    ],
  });

  const onSubmit = useCallback(
    async (settings: FormValues) => {
      const { layout } = settings;

      const prevLayout = screenData?.digitalReception.settings?.layout;

      const nextLayout: IDigitalReceptionLayout<{
        en?: string;
        nl?: string;
      }> = {};

      if (prevLayout && layout) {
        for (const [key, value] of Object.entries(layout)) {
          if (key !== "__typename") {
            if (key === "welcomeMessage") {
              nextLayout[key] = { [language]: value };
            } else if (key === "manualCheckinFields") {
              nextLayout[key] = {
                type: "Guest",
                fields: value.fields.map((field: any) => {
                  const prevField = prevLayout.manualCheckinFields?.fields.find(
                    (prevField) => field.fieldName === prevField.fieldName,
                  );
                  return {
                    required: get(field, "required"),
                    fieldName: get(field, "fieldName"),
                    fieldLabel: {
                      ...omit(prevField?.fieldLabel, ["__typename"]),
                      [language]: get(field, "fieldLabel"),
                    },
                  };
                }),
              };
            } else {
              nextLayout[
                key as keyof Omit<
                  IDigitalReceptionLayout<string>,
                  "welcomeMessage" | "manualCheckinFields"
                >
              ] = {
                enabled: get(value, "enabled"),
                text: {
                  [language]: get(value, "text"),
                },
              };
            }
          }
        }
      }

      onUpdate({
        variables: {
          input: {
            id: screenId,
            settings: {
              layout: nextLayout,
            },
          },
        },
      }).then(() => {
        toast.success<string>(t("screens:screen.screenRoute.toast.updated"));

        const previewEl = document.querySelector<HTMLIFrameElement>("#preview");

        if (previewEl) {
          previewEl.src += "";
        }
      });
    },
    [onUpdate, t, screenId, language, screenData],
  );

  return (
    <div className="container-fluid">
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group mb-4">
              <label htmlFor="welcomeMessage">
                {t("screens:screen.settings.layout.welcomeMessage")}
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    {language.toUpperCase()}
                  </span>
                </div>
                <input
                  {...methods.register("layout.welcomeMessage", {
                    onChange: ({ target: { value } }) => {
                      onChangePreview(
                        `layout.welcomeMessage.${language}`,
                        value,
                      );
                    },
                  })}
                  className="form-control"
                />
              </div>
            </div>
            <HomeButtonController
              name="layout.checkin"
              label={t(`screens:screen.settings.layout.checkin`)}
              onChangePreview={onChangePreview}
            />
            <HomeButtonController
              name="layout.checkout"
              label={t(`screens:screen.settings.layout.checkout`)}
              onChangePreview={onChangePreview}
            />
            <HomeButtonController
              name="layout.package"
              label={t(`screens:screen.settings.layout.package`)}
              onChangePreview={onChangePreview}
            />
            <HomeButtonController
              name="layout.food"
              label={t(`screens:screen.settings.layout.food`)}
              onChangePreview={onChangePreview}
            />
            {!isFree && (
              <HomeButtonController
                name="layout.callReception"
                label={t(`screens:screen.settings.layout.callReception`)}
                onChangePreview={onChangePreview}
              />
            )}
          </div>
          <div className="col-lg-4">
            <p>{t("screens:screen.settings.layout.manualCheckin")}</p>
            <HomeButtonController
              name="layout.integratedManualCheckin"
              label={t(
                "screens:screen.settings.layout.integratedManualCheckin",
              )}
              onChangePreview={onChangePreview}
              isDisabled={isIndependentManualCheckinEnabled}
            />
            <HomeButtonController
              name="layout.independentManualCheckin"
              label={t(
                "screens:screen.settings.layout.independentManualCheckin",
              )}
              onChangePreview={onChangePreview}
              isDisabled={isIntegratedManualCheckinEnabled}
            />
            {(isIntegratedManualCheckinEnabled ||
              isIndependentManualCheckinEnabled) && (
              <div>
                {fields.map((field, index) => (
                  <ManualCheckinFieldController
                    key={field.id}
                    index={index}
                    remove={remove}
                    name={`layout.manualCheckinFields.fields.${index}`}
                    label={t("screens:screen.settings.layout.required")}
                  />
                ))}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    append({
                      required: false,
                      fieldName: "fieldName",
                      fieldLabel: "Your field",
                    });
                  }}
                >
                  {t("screens:screen.settings.layout.addField")}
                </button>
              </div>
            )}
          </div>
          <div className="col-lg-4 mt-5">
            <div className="col-12 mb-2">
              <div
                className=" position-absolute p-0"
                style={{ zIndex: 100, width: 600 / (1920 / 1080), height: 600 }}
              />
              <iframe
                id="preview"
                ref={previewRef}
                title={`screen-${screenId}`}
                className="border border-dark"
                src={`${screenData?.digitalReception?.previewUrl}?preview=true`}
                style={{
                  width: 600 / (1920 / 1080),
                  height: 600,
                }}
                frameBorder={0}
                marginHeight={0}
                marginWidth={0}
              />
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Layout;
